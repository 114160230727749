'use client';

import { Post } from "@/types/sanity.d";
import Image from "next/image";
import Link from "next/link";
import { CalendarDays } from "lucide-react";
import { useEffect, useState } from "react";
import { getBlogPosts } from "@/app/actions/getBlogPosts";

const Blog = () => {
  const [posts, setPosts] = useState<Post[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await getBlogPosts();
        setPosts(fetchedPosts);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, []);

  if (isLoading || posts.length === 0) {
    return <div className="container mx-auto px-4 py-12">Loading blog posts...</div>;
  }

  const secondaryPosts = posts.slice(1, 4);
  const featuredPost = posts[0];
  const featuredPostImageSrc = `https://cdn.sanity.io/images/82vh3fyz/production/${featuredPost.coverImage.asset._ref
    .replace("image-", "")
    .replace("-png", ".png")
    .replace("-jpg", ".jpg")
    .replace("-jpeg", ".jpeg")
    .replace("-webp", ".webp")
    .replace("-gif", ".gif")
    .replace("-svg", ".svg")
  }`

  return (
    <section className="container mx-auto px-4 py-12">
      {/* Featured Post */}
      <article className="mb-12 group" key={featuredPost._id}>
        <Link
          href="https://blog.mbras.com.br"
          className="grid md:grid-cols-2 gap-8 bg-background hover:bg-card/5 transition-colors duration-300"
        >
          <div className="relative aspect-[16/9] md:aspect-[4/3] overflow-hidden">
            <Image
              width={800}
              height={600}
              className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-500"
              src={featuredPostImageSrc}
              alt={featuredPost.title || "Featured blog post"}
              loading="lazy"
              quality={85}
            />
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <div className="flex items-center gap-2 text-muted-foreground text-sm">
              <CalendarDays className="w-4 h-4" />
              <time dateTime={featuredPost.date}>
                {new Date(featuredPost.date).toLocaleDateString("pt-BR", {
                  day: "numeric",
                  month: "long",
                  year: "numeric",
                })}
              </time>
            </div>
            <h2 className="text-2xl md:text-3xl font-bold text-foreground">
              {featuredPost.title}
            </h2>
            <p className="text-muted-foreground text-base leading-relaxed">
              {featuredPost.excerpt}
            </p>
            <div className="pt-2">
              <span className="inline-flex items-center text-sm font-medium text-primary hover:text-primary/90 transition-colors">
                Continue lendo →
              </span>
            </div>
          </div>
        </Link>
      </article>

      {/* Secondary Posts Grid */}
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {secondaryPosts.map((post) => {
          const postImageSrc = `https://cdn.sanity.io/images/82vh3fyz/production/${post.coverImage.asset._ref
            .replace("image-", "")
            .replace("-png", ".png")
            .replace("-jpg", ".jpg")
            .replace("-jpeg", ".jpeg")
            .replace("-webp", ".webp")
            .replace("-gif", ".gif")
            .replace("-svg", ".svg")
          }`;

          return (
            <article key={post._id} className="group">
              <Link href="https://blog.mbras.com.br" className="block">
                <div className="relative aspect-[16/9] overflow-hidden mb-4">
                  <Image
                    width={600}
                    height={338}
                    className="object-cover w-full h-full group-hover:scale-105 transition-transform duration-500"
                    src={postImageSrc}
                    alt={post.title || "Blog post"}
                    loading="lazy"
                    quality={85}
                  />
                </div>
                <div className="space-y-3">
                  <div className="flex items-center gap-2 text-muted-foreground text-sm">
                    <CalendarDays className="w-4 h-4" />
                    <time dateTime={post.date}>
                      {new Date(post.date).toLocaleDateString("pt-BR", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })}
                    </time>
                  </div>
                  <h3 className="text-xl font-bold text-foreground group-hover:text-primary/90 transition-colors">
                    {post.title}
                  </h3>
                  <p className="text-muted-foreground text-sm leading-relaxed line-clamp-3">
                    {post.excerpt}
                  </p>
                  <div className="pt-2">
                    <span className="inline-flex items-center text-sm font-medium text-primary hover:text-primary/90 transition-colors">
                      Continue lendo →
                    </span>
                  </div>
                </div>
              </Link>
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default Blog;
