//page.tsx
"use client";
import React, { useEffect, useState, Suspense } from "react";
import { AnimatePresence, motion } from "framer-motion";
import api from "@/services/api.service";
import { useLayout } from "@/components/LayoutProvider";
import Link from "next/link";
import { Button } from "@/components/ui/button";
import Blog from "@/components/Blog";
import { BookCheck, ChevronRight, Sparkles } from "lucide-react";
import Animated from "@/components/animated";
import HeroImage from "@/components/HeroImage";

interface FeaturedProperty {
  objectID: string;
  title: string;
  description: string;
  ref?: string;
  property_type?: string;
  neighborhood?: string;
  commercial_neighborhood?: string;
  sale_value?: number;
  rent_value?: number;
  bedrooms?: number;
  suites?: number;
  bathrooms?: number;
  total_area?: number;
  usable_area?: number;
  imagekit?: string;
  type?: string;
  is_for_sale?: boolean;
  is_for_rent?: boolean;
}

// Componentes com lazy loading
const FeaturedPropertiesSection = React.lazy(
  () => import("@/components/home/FeaturedSection")
);
const TestimonialsSection = React.lazy(
  () => import("@/components/global/testimonials-section")
);
const NewsletterSection = React.lazy(
  () => import("@/components/global/newsletter-section")
);
const MBRASClub = React.lazy(
  () => import("@/components/global/mbrasclub-section")
);
const AboutMBRASSection = React.lazy(() => import("@/components/AboutMBRAS"));

export default function Home() {
  const [featuredProperties, setFeaturedProperties] = useState<
    FeaturedProperty[]
  >([]);
  const { isSearchExpanded, setIsSearchExpanded } = useLayout();

  async function fetchFeaturedProperties() {
    try {
      const response = await api.get("/geral/home");
      const { featured_immobiles } = response.data;
      setFeaturedProperties(featured_immobiles);
    } catch (error) {
      console.error("Erro ao buscar propriedades em destaque:", error);
    }
  }

  useEffect(() => {
    fetchFeaturedProperties();
  }, []);

  return (
    <>
      <AnimatePresence mode="popLayout">
        {!isSearchExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-background min-h-screen"
          >
            <section className="relative h-[80vh] min-h-[550px] w-full flex items-center justify-center text-center">
              {/* Background Gradient for visual effect */}
              <div className="absolute inset-0 bg-gradient-to-b from-slate-950/50 to-slate-950/30 z-10" />

              {!isSearchExpanded && <HeroImage />}
              <div className="relative z-20 max-w-3xl mx-auto px-6">
                <motion.h1
                  className="text-4xl md:text-6xl font-bold leading-medium mb-8 text-gray-200"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4, delay: 0.1 }}
                >
                  Referência em Altíssimo Padrão
                </motion.h1>
                <Animated>
                  <span className="flex flex-col md:flex-row gap-4 items-center justify-center">
                    <>
                      <Button
                        aria-label={"Busca Inteligente"}
                        size="lg"
                        className="px-8 py-6 font-semibold text-base before:ease relative h-12 overflow-hidden border border-transparent hover:border-border bg-primary hover:bg-primary text-primary-foreground shadow-2xl transition-all before:absolute before:right-0 before:top-0 before:h-12 before:w-6 before:translate-x-12 before:rotate-6 before:bg-white before:opacity-10 before:duration-700 hover:shadow-primary hover:before:-translate-x-64"
                        variant={"secondary"}
                        onClick={() =>
                          document.getElementById("smart-search-input")?.focus()
                        }
                      >
                        <Sparkles />
                        <span>Busca Inteligente</span>
                        <ChevronRight className="ml-2 -mr-1 h-6 w-6" />
                      </Button>
                      <Link href={"/traditional"}>
                        <Button
                          aria-label={"Busca tradicional"}
                          size="lg"
                          className="px-8 font-semibold text-base h-12 border border-transparent"
                          variant={"secondary"}
                        >
                          <BookCheck />
                          <span className="drop-shadow-xl">
                            Busca tradicional
                          </span>
                          <ChevronRight className="ml-2 -mr-1 h-6 w-6" />
                        </Button>
                      </Link>
                    </>
                  </span>
                </Animated>
              </div>
            </section>
            <FeaturedPropertiesSection
              featuredProperties={featuredProperties}
              setIsSearchExpanded={setIsSearchExpanded}
            />

            <Suspense fallback={<div>Loading MBRAS Club...</div>}>
              <MBRASClub />
            </Suspense>

            <Suspense fallback={<div>Loading About MBRAS...</div>}>
              <AboutMBRASSection />
            </Suspense>

            <Suspense fallback={<div>Loading testimonials...</div>}>
              <TestimonialsSection />
            </Suspense>

            <Suspense fallback={<div>Loading newsletter...</div>}>
              <NewsletterSection />
            </Suspense>

            <Suspense fallback={<div>Loading blog...</div>}>
              <Blog />
            </Suspense>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}
